import React from "react"

import "./pageBody.scss"

var PageBody = props => (
  <div className={`page-body-component ${props.className || ""}`}>
    {props.children}
  </div>
)

export default PageBody
