import React from "react"

import "./quoteImage.scss"

var QuoteImage = props => (
  <div className={`quote-image-component ${props.className || ""}`}>
    <div className="imageWrap">
      <div className="tint" />
      {/* <div
        className="image"
        style={{ backgroundImage: `url("${props.src}")` }}
      /> */}
    </div>
    <p>{props.quote}</p>
  </div>
)

export default QuoteImage
