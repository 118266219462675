import React from "react"
import QuoteImage from "../quote-image/quoteImage"
import { homePage } from "../../utils/text"

import "./quoteImages.scss"

var QuoteImages = props => (
  <div className={`quote-images-component ${props.className || ""}`}>
    {homePage.quotes.map(image => (
      <QuoteImage
        className="quote-image"
        src={image.src}
        quote={image.quote}
        key={image.quote}
      />
    ))}
  </div>
)

export default QuoteImages
