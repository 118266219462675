import React from "react"

import "./banner.scss"

var Banner = props => (
  <div
    className="banner-component"
    style={{
      maxHeight: props.height,
      height: props.heightMobile ? props.heightMobile : "100vw",
    }}
  >
    <div className="darken" />
    <div
      className="image"
      style={{
        backgroundImage: `url("${props.src}")`,
        backgroundPositionY: props.backgroundPosY
          ? props.backgroundPosY
          : "center",
      }}
    />
    <div className="text">
      <div className="max-width">
        <h1>{props.title}</h1>
        <h2>{props.subTitle}</h2>
      </div>
    </div>
  </div>
)

export default Banner
