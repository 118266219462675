import React from "react"
import CashIcon from "mdi-react/CashIcon"

import RunIcon from "mdi-react/RunIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import insurance from "../../images/components/insurance.jpg"
import insuranceBody1 from "../../images/components/insurance-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./assistance-with-enrolling-in-health-insurance-program-of-choice.scss"

var AssistanceWithEnrollingInHealthInsuranceProgramOfChoice = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="assistance-with-enrolling-in-health-insurance-program-of-choice-page">
      <Banner
        src={insurance}
        title="Assistance with Enrolling in Health Insurance Program of Choice"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Assistance with Enrolling in Health Insurance Program of Choice
        </BorderTitle>
        <div className="body">
          <img className="left" src={insuranceBody1} alt="" /><p>As our healthcare system continues to change, there are many seniors who have been left without adequate insurance.  This means that preventative health services, such as the flu vaccination, may be difficult to obtain for them.</p><p>Because health insurance is so complicated, we aim to help our senior participants understand the processes of enrolling in an insurance policy and for what they may qualify.  We want to not only teach them about how insurance works, but we also want to empower them to take charge of their health in this way.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/program-wide-events-to-promote-physical-and-mental-health-of-seniors"
            className="first"
            icon={RunIcon}
            title="Program-Wide Events to Promote Physical and Mental Health of Seniors"
            />
          <ComponentNavigator
            next
            link="/components-year-two/financial-literacy-and-asset-security-program-for-family-members"
            icon={CashIcon}
            title="Financial Literacy and Asset Security Program for Family Members"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default AssistanceWithEnrollingInHealthInsuranceProgramOfChoice
