import React from "react"
import { Link } from "gatsby"

import "./componentNavigator.scss"
import BorderTitle from "./../border-title/borderTitle"
import ComponentIcon from "./../component-icon/componentIcon"

var ComponentNavigator = props => (
  <Link
    to={props.link}
    className={`component-navigator-component ${props.className || ""}`}
  >
    <BorderTitle>{props.next ? "NEXT" : "PREV"}</BorderTitle>
    <div className="body">
      <ComponentIcon className="icon" desktopBreak icon={props.icon} />
      <p className="title">{props.title}</p>
    </div>
  </Link>
)

export default ComponentNavigator
