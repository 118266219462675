import React from "react"

import "./componentIcon.scss"

var ComponentIcon = props => (
  <div
    className={`component-icon-component ${props.className ||
      ""} ${props.desktopBreak && "desktop"}`}
  >
    <div className="left-half" />
    <div className="right-half" />
    <div className="icon">
      <props.icon />
    </div>
  </div>
)

export default ComponentIcon
